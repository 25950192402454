.unewslist li{
    list-style: none;
}
.rownews{
    padding: 10px; margin: 15px;
}
.unewslist{
    padding: 10px;
    font-family: Montserrat;
    font-weight: 400;
    font-size: 15px; border: none;
}
.colnews{
   margin-bottom: 20px;
}
.newstitle{
    font-family: Montserrat;
    font-weight: 400;
    font-size: 18px; 
}
.newspara{
    font-family: Montserrat;
    font-weight: 400;
    font-size: 15px; 
}
.newsreadmore{
    background: transparent;
    border: none;
    color: #666666; 
}
.newsreadmore:hover{
    color: blue;
    background: transparent;
    border: none;
}