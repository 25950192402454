.subcard{
    border: none;
    margin-top: 100px;
}
.titlename{
    font-family: Montserrat;
    font-weight: 400;
    font-size: 30px;
    line-height: 1.7;
    color: black;
    margin: 0px;
}
.subname{
    font-family: Montserrat;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.7;
    color: black;
    margin: 0px;
}
.card subcard-title{
    font-family: Montserrat;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.7;
    color: black;
    margin: 0px;
}

