@media (min-width: 769px) {
  .video-card {
    /* Set height to 500px for large screens */
    height: 530px;
  }
}
.body{
  margin: 0px;
  padding: 0px;
}


.text-project{
  font-family: Montseerrat;
  font-weight: 400;
  font-size: 40px;
  line-height: 1.7;
  color: rgb(15, 15, 15);
  margin: 0px;
  text-align: center;
  
}


.service {
  transition: all 0.6s; 
  overflow: hidden;/* Apply transition only to the card's transform property */
}

.card-img-top:hover {
  transform: scale(1.1);
  transition: all 0.6s; 
}

.card-img-top {
  width: 100%; /* Make sure the image width is 100% inside the card */
}


.learn-more-link:hover {
  color: rgb(72, 0, 255); /* Change the color to your desired hover color */
}
.learn-more-link{
  text-decoration: none;
  color: black;
}

.textCenterCustom {
  text-align: center;
}

.card-body.d-flex.justify-content-start.align-items-center:hover {
  border-radius: 10px;
background-color: rgb(247, 163, 8);
transform: scale(1.1); 
}
  


.learn-more-link{
  font-family: Montserrat;
  font-weight: 400;
  font-size: 15px;
  color:#666666;

}



.card-title.services{
  font-family: Montseerrat;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.7;
  color: black;
  margin: 0px;
}



.card-body > div {
  pointer-events: none; /* This prevents hover events on the icon div */
}
.service{
  transition: "all 0.6s ease-in-out",



}
@media (max-width: 767px) {
  .service {
      margin-top: 20px; /* Add padding to the top of cards on mobile screens */
  }
  .text-container {
      top: 10%; 
      left:20%;/* Adjust vertical positioning as needed */
  }
}

/* Media query for screens with a minimum width of 768px */
@media (min-width: 768px) {
  .text-container h3 {
      text-align: center;
      
  }
}
@media (max-width: 767px) {
  .video-card {
    width: 100%; 
  }

  .video-card video {
    width: 100%; 
    height: auto;
    padding-top: 30%; 
  }
}