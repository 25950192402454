

@media (max-width: 768px) {

 .component-class {
      font-size: 14px;
      padding: 10px;
     
 } 
  }
  

  @media (min-width: 769px) and (max-width: 1024px) {
   .component-class {
      font-size: 16px;
      padding: 15px;
   }

  }
  
  /* Media query for screens larger than 1024px */
  @media (min-width: 1025px) {
    /* Adjust styling for larger screens here */
  
     .component-class {
      font-size: 18px;
      padding: 20px;
   
 } 
  }
  
  .component-class{
     padding: 0;
     margin: 0; 
   overflow-x: hidden;
     position: relative;
    }